import React from 'react';
// Revert to direct component imports
import Sidebar from './components/Sidebar';
import Summary from './components/Summary';
import Skills from './components/Skills';
import Experience from './components/Experience';
import Education from './components/Education';
import Interests from './components/Interests';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="layout-wrapper">
        <Sidebar />

        <main className="main-content">
          <Summary id="about" />
          <Skills id="skills" />
          <Experience id="experience" />
          <Education id="education" />
          <Interests id="interests" />
        </main>
      </div>
    </div>
  );
}

export default App;
