import React, { useMemo } from 'react';
import './Skills.css';
import experienceData from '../data/experienceData.json';
import { getTechIcon } from '../utils/iconUtils';

const Skills = ({ id }) => {
  // Extract and categorize all unique technologies from experience data
  const categorizedSkills = useMemo(() => {
    const allTechnologies = new Set();
    experienceData.forEach(job => {
      if (job.technologies) {
        job.technologies.forEach(tech => allTechnologies.add(tech));
      }
    });

    // Define categories and their associated technologies
    const categories = {
      Languages: ['JavaScript', 'TypeScript', 'PHP', 'HTML', 'CSS', 'CSS3', 'HTML5', 'SASS', 'Python'],
      Frameworks: ['React', 'Angular', 'Angular.js', 'Node.js', 'NestJS', 'Express', 'Redux', 'Bootstrap', 'jQuery', 'Fastify', 'Django'],
      Databases: ['MongoDB', 'MySQL', 'PostgreSQL', 'Cosmos DB', 'Redis', "DynamoDB"],
      Cloud: ['AWS', 'Azure', 'Serverless'],
      Tools: ['Docker', 'Kubernetes', 'Git', 'CI/CD', 'Webpack', 'Jest', 'FFmpeg', 'Kafka'],
      Concepts: ['REST API', 'GraphQL', 'Microservices', 'Socket.io', 'JWT', 'OAuth', 'HIPAA Compliance', 'Big Data', 'Event Driven', 'Responsive Design'],
      Other: []
    };

    // Categorize each technology
    const result = {};
    Object.keys(categories).forEach(category => {
      result[category] = [];
    });

    allTechnologies.forEach(tech => {
      let categorized = false;
      for (const [category, techs] of Object.entries(categories)) {
        if (techs.includes(tech)) {
          result[category].push(tech);
          categorized = true;
          break;
        }
      }
      if (!categorized) {
        result.Other.push(tech);
      }
    });

    // sort technologies in side category by frequency
    Object.keys(result).forEach(category => {
      result[category].sort((a, b) => {
        const aCount = experienceData.reduce((count, job) => count + (job.technologies?.includes(a) ? 1 : 0), 0);
        const bCount = experienceData.reduce((count, job) => count + (job.technologies?.includes(b) ? 1 : 0), 0);
        return bCount - aCount; // Sort in descending order
      });
    });

    // Remove empty categories
    return Object.fromEntries(
      Object.entries(result).filter(([_, technologies]) => technologies.length > 0)
    );
  }, []);

  return (
    <section id={id} className="section-skills no-print" data-print-new-page="true">
      <div className="container">
        <h2 className="section-title">Skills & Technologies</h2>
        <div className="skills-content">
          {Object.entries(categorizedSkills).map(([category, technologies]) => (
            <div className="skill-group" key={category}>
              <h3 className="skill-group-title">{category}</h3>
              <div className="skill-list">
                {technologies.map((tech, index) => (
                  <span className="skill-tag" key={index}>
                    {getTechIcon(tech)} <span className="skill-name">{tech}</span>
                  </span>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;
