import React from 'react';
import './Experience.css';
import experienceData from '../data/experienceData.json';
import { getTechIcon } from '../utils/iconUtils';

const Experience = ({ id }) => {
  return (
    <section id={id} className="section-experience">
      <div className="container">
        <h2 className="section-title">Work Experience</h2>
        <div className="timeline">
          {experienceData.map((job, index) => (
            <div className="timeline-item" key={index}>
              <div className="timeline-marker"></div>
              <div className="timeline-content">
                <div className="timeline-header">
                  <h3>{job.title}</h3>
                  <div className="timeline-meta">
                    <span className="company">{job.company}</span>
                    <span className="period">{job.period}</span>
                  </div>
                </div>
                <div className="timeline-body">
                  <ul>
                    {job.responsibilities.map((responsibility, idx) => (
                      <li key={idx}>{responsibility}</li>
                    ))}
                  </ul>

                  <div className="technologies">
                    <h4>Tools & Technologies</h4>
                    <div className="tech-tags">
                      {job.technologies && job.technologies.map((tech, techIdx) => (
                        <span className="tech-tag" key={techIdx}>
                          {getTechIcon(tech)} <span className="tech-name">{tech}</span>
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Experience;
