import React from 'react';
import {
  FaReact, FaNodeJs, FaPython, FaJava, FaHtml5, FaCss3, FaJs,
  FaDatabase, FaAws, FaDocker, FaAngular, FaBootstrap, FaGitAlt,
  FaSass, FaPhp, FaServer, FaMicrosoft,
  FaVideo,
  FaExchangeAlt,
  FaMap
} from 'react-icons/fa';
import {
  SiTypescript, SiMongodb, SiExpress, SiPostgresql, SiGraphql,
  SiRedux, SiMysql, SiRedis, SiSocketdotio, SiWebpack,
  SiJest, SiKubernetes, SiAngular
} from 'react-icons/si';

// Map technology names to their corresponding icons
export const getTechIcon = (techName) => {
  const iconMap = {
    // Frontend Technologies
    'React': <FaReact />,
    'React.js': <FaReact />,
    'ReactJS': <FaReact />,
    'Angular': <FaAngular />,
    'Angular.js': <SiAngular />,
    'HTML': <FaHtml5 />,
    'HTML5': <FaHtml5 />,
    'CSS': <FaCss3 />,
    'CSS3': <FaCss3 />,
    'JavaScript': <FaJs />,
    'JS': <FaJs />,
    'TypeScript': <SiTypescript />,
    'TS': <SiTypescript />,
    'jQuery': <FaJs />,
    'Bootstrap': <FaBootstrap />,
    'SASS': <FaSass />,
    'Redux': <SiRedux />,
    'Responsive Design': <FaCss3 />,
    'Webpack': <SiWebpack />,

    // Backend Technologies
    'Node.js': <FaNodeJs />,
    'NodeJS': <FaNodeJs />,
    'NestJS': <FaNodeJs />,
    'Express': <SiExpress />,
    'Fastify': <FaNodeJs />,
    'Python': <FaPython />,
    'Django': <FaPython />,
    'Java': <FaJava />,
    'PHP': <FaPhp />,
    'REST API': <FaServer />,
    'Microservices': <FaServer />,
    'Event Driven': <FaExchangeAlt />,

    // Database Technologies
    'MongoDB': <SiMongodb />,
    'PostgreSQL': <SiPostgresql />,
    'SQL': <FaDatabase />,
    'MySQL': <SiMysql />,
    'Cosmos DB': <FaDatabase />,
    'DynamoDB': <FaDatabase />,
    'Redis': <SiRedis />,
    'Big Data': <FaDatabase />,

    // API & Integration
    'GraphQL': <SiGraphql />,
    'Socket.io': <SiSocketdotio />,
    'JWT': <FaServer />,
    'OAuth': <FaServer />,
    'Google Maps API': <FaMap />,

    // DevOps & Cloud
    'AWS': <FaAws />,
    'Azure': <FaMicrosoft />,
    'Docker': <FaDocker />,
    'Kubernetes': <SiKubernetes />,
    'Git': <FaGitAlt />,
    'CI/CD': <FaGitAlt />,
    'Serverless': <FaServer />,
    'Jest': <SiJest />,

    // Specialized Technologies
    'HIPAA Compliance': <FaServer />,
    'FFmpeg': <FaServer />,
    'Kafka': <FaServer />,
    'Video Streaming': <FaVideo />,
  };

  // Return the icon if it exists in the map, otherwise return null
  return iconMap[techName] || null;
};
