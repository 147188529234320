import React from 'react';
import './Education.css';

const Education = ({ id }) => {
  return (
    <section id={id} className="section-education">
      <div className="container">
        <h2 className="section-title">Education</h2>
        <div className="education-content">
          <div className="education-card">
            <div className="education-details">
              <h3>Master's in Computer Sciences</h3>
              <p className="institution">International Scientific-Technical University named after Academician Yuri Bugay</p>
              <p className="period">2010</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Education;
