import React from 'react';
import './Interests.css';

const Interests = ({ id }) => {
  return (
    <section id={id} className="section-interests">
      <div className="container">
        <h2 className="section-title">Interests</h2>
        <div className="interests-content">
          <div className="interest-card">
            <div className="interest-details">
              <h3>Traveling</h3>
              <p className="interest-description">
                I like to travel with my family and find new places that inspire me to work with pleasure.
                Exploring different cultures and environments helps me gain fresh perspectives and creative ideas.
              </p>
            </div>
          </div>

          <div className="interest-card">
            <div className="interest-details">
              <h3>Continuous Learning</h3>
              <p className="interest-description">
                Most of my free time I try to learn something new. It's always good to be knowledgeable
                in new and mainstream technologies and tools. This passion for learning keeps me on the
                cutting edge of technology and helps me grow both personally and professionally.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Interests;
