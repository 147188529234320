import React from 'react';
import './Summary.css';
import experienceData from '../data/experienceData.json';
import { FaFacebookF, FaLinkedinIn, FaCalendarAlt } from 'react-icons/fa';

const Summary = ({ id }) => {
  // Calculate years of experience based on the earliest job
  const calculateExperience = () => {
    const currentYear = new Date().getFullYear();
    const earliestYear = Math.min(...experienceData.map(job => {
      const startYear = parseInt(job.period.split(' - ')[0]);
      return isNaN(startYear) ? currentYear : startYear;
    }));
    return currentYear - earliestYear;
  };

  // Get unique companies
  const uniqueCompanies = [...new Set(experienceData.map(job => job.company))].length;

  // Get unique technologies
  const allTechnologies = experienceData.flatMap(job => job.technologies);

  // Top technologies by frequency
  const techFrequency = allTechnologies.reduce((acc, tech) => {
    acc[tech] = (acc[tech] || 0) + 1;
    return acc;
  }, {});

  const topTechnologies = Object.entries(techFrequency)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 5)
    .map(entry => entry[0]);

  const yearsOfExperience = calculateExperience();

  return (
    <section id={id} className="section-summary">
      <div className="container">
        <h1 className="section-title">Mykyta <span>Matsapura</span></h1>
        <div className="summary-content">
          <div className="summary-text">
            <p><strong>Results-driven Senior Node.js Developer and JavaScript Expert</strong> with {yearsOfExperience}+ years of hands-on experience delivering high-impact technical solutions across {uniqueCompanies} organizations. Demonstrated success in healthcare, fintech, education, and telecommunications sectors with a proven track record of accelerating product development and optimizing system performance.</p>

            <p>My technical toolkit includes <strong>{topTechnologies.slice(0, 3).join(', ')}</strong>, enabling me to consistently deliver scalable, maintainable solutions that drive business objectives. I excel in cross-functional collaboration, having successfully bridged the gap between technical teams and stakeholders to ensure alignment with organizational goals. My projects have delivered measurable outcomes including reduced infrastructure costs, improved application performance, and enhanced user experiences.</p>

            <p>Career progression from FullStack Developer to <strong>Senior Node.js Developer and Solution Architect</strong> demonstrates my ability to adapt to evolving technical landscapes while maintaining a customer-centric focus. I bring a unique blend of deep technical expertise and strategic thinking, allowing me to both contribute hands-on code and provide architectural guidance that aligns with business requirements. Bilingual in English and Ukrainian with exceptional communication skills.</p>

            <p>I thrive in agile environments and consistently deliver on-time, on-budget solutions that exceed expectations. My commitment to continuous professional development ensures I remain at the cutting edge of technology trends while applying best practices in software development methodology. Ready to bring immediate value to your organization through innovative problem-solving and technical leadership.</p>
          </div>

          <div className="social-links-container">
            <ul className="social-links">
              <li className="social-item">
                <a href="https://www.facebook.com/n.matsapura" target="_blank" rel="noopener noreferrer">
                  <div className="icon-circle">
                    <FaFacebookF className="social-icon" />
                  </div>
                </a>
              </li>
              <li className="social-item">
                <a href="https://www.linkedin.com/in/nikita-matsapura-85678170/" target="_blank" rel="noopener noreferrer">
                  <div className="icon-circle">
                    <FaLinkedinIn className="social-icon" />
                  </div>
                </a>
              </li>
              <li className="social-item">
                <a href="https://cal.com/nicomua" target="_blank" rel="noopener noreferrer">
                  <button className="schedule-btn">
                    <FaCalendarAlt className="btn-icon" /> Schedule interview
                  </button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Summary;
