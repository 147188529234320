import React, { useState, useEffect } from 'react';
import './Sidebar.css';
// import profilePic from '../assets/cv.png'; // Update path as needed

const Sidebar = () => {
  const [activeSection, setActiveSection] = useState('');

  // Add a click handler for smooth scrolling
  const handleNavClick = (e, targetId) => {
    e.preventDefault();
    const target = document.getElementById(targetId);
    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Add print handler function
  const handlePrint = (e) => {
    e.preventDefault();
    window.print();
  };

  // Set up intersection observer to track which section is currently visible
  useEffect(() => {
    const sectionIds = ['about', 'skills', 'experience', 'education', 'interests'];

    const observerOptions = {
      root: null, // viewport
      rootMargin: '-20% 0px', // Less aggressive margin to ensure sections are detected
      threshold: 0.1, // Lower threshold to trigger detection more easily
    };

    const observerCallback = (entries) => {
      // Find the first section that's intersecting, prioritizing by scroll position
      const intersectingEntries = entries.filter(entry => entry.isIntersecting);

      if (intersectingEntries.length > 0) {
        // Sort by their position in the viewport (top entries first)
        const sortedEntries = intersectingEntries.sort(
          (a, b) => Math.abs(a.boundingClientRect.top) - Math.abs(b.boundingClientRect.top)
        );

        if (sortedEntries[0]?.target?.id) {
          setActiveSection(sortedEntries[0].target.id);
        }
      }
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    // Observe all sections
    sectionIds.forEach(id => {
      const element = document.getElementById(id);
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      // Clean up the observer on component unmount
      sectionIds.forEach(id => {
        const element = document.getElementById(id);
        if (element) observer.unobserve(element);
      });
    };
  }, []);

  return (
    <aside className="sidebar">
      <div className="sidebar-content">
        <div className="sidebar-header print-inline">
          <div className="profile-image">
            <img src='/assets/cv.png' alt="Profile" />
          </div>
        </div>

        <div className="sidebar-info">
          <div className="sidebar-section print-inline">
            <h3>Contact</h3>
            <ul className="contact-list">
              <li>
                <a href="mailto:macaniki+work@gmail.com">
                  <span className="icon">✉</span>
                  <span>macaniki@gmail.com</span>
                </a>
              </li>
              <li>
                <a href="tel:+380934367393">
                  <span className="icon">📱</span>
                  <span>+380 93 436 73 93</span>
                </a>
              </li>
              <li>
                <span className="icon">📍</span>
                <span>Ukraine</span>
              </li>
              <li>
                <a href="#print" onClick={handlePrint}>
                  <span className="icon">📄</span>
                  <span>Get CV</span>
                </a>
              </li>
            </ul>
          </div>

          <div className="sidebar-section no-print">
            <h3>Navigation</h3>
            <nav className="sidebar-nav">
              <ul>
                <li>
                  <a
                    href="#about"
                    onClick={(e) => handleNavClick(e, 'about')}
                    className={activeSection === 'about' ? 'active' : ''}
                  >
                    About Me
                  </a>
                </li>
                <li>
                  <a
                    href="#skills"
                    onClick={(e) => handleNavClick(e, 'skills')}
                    className={activeSection === 'skills' ? 'active' : ''}
                  >
                    Skills
                  </a>
                </li>
                <li>
                  <a
                    href="#experience"
                    onClick={(e) => handleNavClick(e, 'experience')}
                    className={activeSection === 'experience' ? 'active' : ''}
                  >
                    Experience
                  </a>
                </li>
                <li>
                  <a
                    href="#education"
                    onClick={(e) => handleNavClick(e, 'education')}
                    className={activeSection === 'education' ? 'active' : ''}
                  >
                    Education
                  </a>
                </li>
                <li>
                  <a
                    href="#interests"
                    onClick={(e) => handleNavClick(e, 'interests')}
                    className={activeSection === 'interests' ? 'active' : ''}
                  >
                    Interests
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
